<template>
	<!-- 提现操作组件 ，用户提现 、 商户提现 -->
	<div class="kd-withdraw">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space style="margin-bottom: 12px;">
				<span class="f13">提现状态：</span>
				<a-radio-group v-model:value="search.withdraw_status" button-style="solid" @change="getWithdrawList(1,info.limit)">
					<a-radio-button :value="-1 ">全部</a-radio-button>
					<a-radio-button :value="0 ">待审核</a-radio-button>
					<a-radio-button :value="1 ">已通过</a-radio-button>
					<a-radio-button :value="2 ">未通过</a-radio-button>
				</a-radio-group>
			</a-space>
			<br>
			<a-space>
				<span class="f13">查询参数：</span>
				<a-input v-model:value="search.name" placeholder="姓名" style="width: 240px;" />
				<a-input-search style="width: 320px;" v-model:value="search.account" placeholder="账号" enter-button @search="getWithdrawList(1,info.limit)" />
			</a-space>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table row-key="id" :pagination="false" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'用户信息',dataIndex:'name',slots:{customRender:'name'}},
				{title:'提现金额',dataIndex:'price',slots:{customRender:'price'}},
				{title:'状态',dataIndex:'withdraw_status',slots:{customRender:'withdraw_status'}},
				{title:'提现方式',dataIndex:'method',slots:{customRender:'method'}},
				{title:'备注',dataIndex:'remark'},
				{title:'操作时间',dataIndex:'create_time',slots:{customRender:'create_time'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]">
			    <template #name="{record}">
					<div v-if="record.account" style="display: flex;align-items: center;" class="f14">
						<a-avatar :size="48" :src="record.account.avatar_url" />
						<div style="margin-left: 12px;">
							<div>{{ record.account.nickname }}</div>
							<div><span class="f12">UID：</span>{{record.account_id}} </div>
							<div><span class="f12">姓名：</span>{{record.name}} </div>
							<div><span class="f12">打款账号：</span>{{record.account_show}} </div>
						</div>
					</div>
					<div v-else>
						<div>UID:{{record.account_id}} </div>
						<div>姓名:{{record.name}} </div>
						<div>打款账号:{{record.account_show}} </div>
					</div>
				</template>
			   
				<template #price="{record}">
					<div><span class="f12">金额：</span> <span style="color: red;">{{ record.price }} </span>元</div>
					<div><span class="f12">手续费：</span><span style="color:#FF9900">{{ record.service_charge}}</span>元 </div>
				</template>
			   
				<template #withdraw_status="{record}">
					<a-tag :color="statusShow(record.withdraw_status,2)" >{{statusShow(record.withdraw_status,1)}}</a-tag>
				</template>
			   
				<template #method="{record}">
					<a-tag :color="record.method == 1 ?'green':'blue'">{{ record.method == 1 ?'微信':'支付宝'}}</a-tag>
				</template>
				<template #create_time="{record}">
					<div><span class="f12">申请时间：</span>{{ record.create_time }}</div>
					<div><span class="f12">操作时间：</span>{{ record.update_time || '--'}}</div>
				</template>
			   
				<template #action="{record}">
					<div v-if="record.withdraw_status== 0" style="cursor: pointer;">
						<a-tag v-has="{action:'wxapp_account_withdraw_agree'}" @click="toAgreeWithdraw(record.id,1)" color="#108ee9">手动打款</a-tag>
						<a-tag v-has="{action:'wxapp_account_withdraw_agree'}" @click="toAgreeWithdraw(record.id,2)" color="#FF0066">实时到账</a-tag>
						<a-tag v-has="{action:'wxapp_account_withdraw_deny' }" @click="toDenyWithdraw(record.id,1)" color="#FF9900">拒绝提现</a-tag>
					</div>
					<div v-if="record.withdraw_status == 1" style="color: #009900;">提现成功</div>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getWithdrawList(info.page,e)}"
					@change="(e)=>{getWithdrawList(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal title="拒绝提现" :visible="denyDialog"  @ok="doDenyWithdraw" @cancel="denyDialog=false" width="500px">
			<a-textarea placeholder="请填写拒绝提现的理由" v-model:value="form.remark" allow-clear :rows="6"/>
		</a-modal>
	</div>
	
</template>

<script>
import userModel from '@/api/user.js'
import { computed, reactive, toRefs } from 'vue'
export default{
	name:'kd-withdraw',
	props:{
		platform:{		// user用户 store 多商户提现
			type:String,
			value:''
		}
	},
	setup(props){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			search:{
				name:'',
				account:'',
				withdraw_status:-1,
			},
			denyDialog:false,
			form:{
				remark:'',
				withdraw_status:'',
				type:''
			}
		})

		const statusShow = computed(()=>{
			return function(e,type){
				return type == 1 ? (e == 0 ? '待审核' : (e == 1 ?'已通过':'已拒绝')) : (e == 0 ? '#FF9933' : (e == 1 ?'#00CC66':'#f50') )
			}
		})

		getWithdrawList(1,_d.info.limit)
		function getWithdrawList(page,limit){
			userModel.getAccountWithdraw(page,limit,{platform:props.platform,..._d.search},res=>{
				_d.info = {limit,...res}
				console.log('res',res);
				
			})
		}

		function toDenyWithdraw(id,type){
			_d.form.id = id 
			_d.form.withdraw_status = 2
			_d.form.type = type
			_d.denyDialog = true
		}

		const doDenyWithdraw = ()=>userModel.hanldWithdraw(_d.form,()=>{
			_d.denyDialog = false
			getWithdrawList(_d.info.page,_d.info.limit)
		})

		const toAgreeWithdraw = (id,type)=>userModel.hanldWithdraw({id,withdraw_status:1,type},()=>{
			getWithdrawList(_d.info.page,_d.info.limit)
		})

		return{
			...toRefs(_d),
			toAgreeWithdraw,
			doDenyWithdraw,
			toDenyWithdraw,
			getWithdrawList,
			statusShow
		}
	},
}
</script>

<style lang="scss">

</style>
